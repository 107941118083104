/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
import { setup as setupImages } from './images';

self.__WB_DISABLE_DEV_LOGS = true; // disable workbox logs, might enable for certain envs
self.addEventListener('install', () => {
  // tells service worker to skip installing and activate it
  self.skipWaiting();
});
self.addEventListener('activate', () => {
  clients.claim();
});

setupImages();
